<template>
        <div class="product-preview" @mouseover="hover = true" @mouseleave="hover = false" @focus="hover = true"  @touchend="hover = false" @touchstart="hover = true" tabindex="0">
            <img :src="'/' + images_array[index]" :width="width" :height="height" :intrinsicsize="width+'x'+height" :alt="alt_array[index]" :loading="lazy ? 'lazy' : 'eager'" :fetchpriority="lazy ? 'low' : 'high'" style="max-width:100%;"> <!-- class="img-fluid" -->
            <div class="product-preview-controls px-2">
                <div v-for="(image, i) in images_array" :key="image" v-on:click="setImage($event, image)" class="product-preview-btn" :class="[(index == i ? 'product-preview-btn-active' : ''), (click == true && index == i ? 'product-preview-btn-active-click' : '')]"></div>
            </div>
        </div>
</template>

<script>
    export default {

        props: ['images', 'width', 'height', 'alts', 'lazy'],

        mounted() {
            this.images_array = this.images.split('|');
            this.alt_array = this.alts.split('|');
        },

        data: function() {
            return {
                index: 0,
                images_array: [],
                alt_array: [],
                hover: false,
                timer: false,
                timeout: null,
                click: false,
            }
        },

        methods: {
            changeImages() {
                this.index++;
                if(this.index >= this.images_array.length) {
                    this.index = 0;
                }
            },

            startTimer() {
                this.timer = true;
                
                this.timeout = setTimeout(() => {
                    if(this.hover) {
                        this.startTimer();
                    }

                    if(this.hover) {
                        this.changeImages();
                    } else {
                        this.index = 0;
                        this.timer= false;
                    }
                }, 1500);
            },
            
            stopTimer() {
                this.timer = false;
                clearTimeout(this.timeout);
            },

            setImage(e, image) {
                e.preventDefault();
                this.click = true;
                this.stopTimer();
                for(let i=0;i<this.images_array.length;i++) {
                    if(image == this.images_array[i]) {
                        this.index = i;
                        break;
                    }
                }
            },

        },

        watch: {
            hover: function() {
                if(this.hover && this.timer == false && this.images_array.length > 1) {
                    this.startTimer();
                    this.changeImages();
                } else if(this.hover == false) {
                    this.index = 0;
                    this.click = false;
                }
            },
        },
    }
</script>
