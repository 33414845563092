/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;



// Vue.directive('click-outside', {
//     bind: function (el, binding, vnode) {
//         el.clickOutsideEvent = function (event) {
//         // here I check that click was outside the el and his children
//         if (!(el == event.target || el.contains(event.target))) {
//             // and if it did, call method provided in attribute value
//             vnode.context[binding.expression](event);
//         }
//         };
//         document.body.addEventListener('click', el.clickOutsideEvent)
//     },
//     unbind: function (el) {
//         document.body.removeEventListener('click', el.clickOutsideEvent)
//     },
// });


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.component('canvas-draw', () => import('./components/CanvasDraw.vue'));
Vue.component('sewing-job-form', () => import('./components/SewingJobForm.vue'));



// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('category-preview', require('./components/CategoryPreview.vue').default);
Vue.component('product-preview', require('./components/ProductPreview.vue').default);
Vue.component('mobile-categories', require('./components/MobileCategories.vue').default);
Vue.component('open-mobile-categories', require('./components/OpenMobileCategories.vue').default);
Vue.component('notifications-wrapper', require('./components/Notifications.vue').default);


Vue.component('last-seen-carousel', () => import('./components/Carousel.vue'));
Vue.component('add-to-cart-btn', () => import('./components/AddToCartBtn.vue'));
Vue.component('add-to-cart-modal', () => import('./components/AddToCartModal.vue'));
Vue.component('notification-item', () => import('./components/NotificationItem.vue'));
Vue.component('filter-btn', () => import('./components/FilterBtn.vue'));
Vue.component('sort-btn', () => import('./components/SortBtn.vue'));
Vue.component('search-keyword', () => import('./components/SearchKeyword.vue'));
Vue.component('display-products', () => import('./components/DisplayProducts.vue'));
Vue.component('send-mail-friend', () => import('./components/SendMailFriend.vue'));
Vue.component('stepper-input', () => import('./components/StepperInput.vue'));
Vue.component('create-address-modal', () => import('./components/CreateAddressModal.vue'));
Vue.component('delivery-method', () => import('./components/DeliveryMethodRadioBtn.vue'));
Vue.component('payment-method', () => import('./components/PaymentMethodRadioBtn.vue'));
Vue.component('price-calculator', () => import('./components/PriceCalculator.vue'));
Vue.component('address-radio', () => import('./components/AddressRadioBtn.vue'));
Vue.component('add-comments-to-order', () => import('./components/AddCommentsToOrder.vue'));


Vue.component('select-listen-change', () => import('./components/SelectListenChange.vue'));



//admin
Vue.component('create-attribute', () => import('./components/CreateAttribute.vue'));
Vue.component('create-attribute-set', () => import('./components/CreateAttributeSet.vue'));
Vue.component('switch-attributes', () => import('./components/SwitchAttributes.vue'));

// Vue.component('product-preview', () => import('./components/ProductPreview.vue') )

// Vue.component(
//     'create-attribute', () => import(
//         /* webpackChunkName: "example-component" */
//         require('./components/CreateAttribute.vue').default
//     )
// );


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

export const bus = new Vue();

const app = new Vue({
    el: '#app',
});







//OLD
/*
Vue.component('mobile-categories', require('./components/MobileCategories.vue').default);
Vue.component('open-mobile-categories', require('./components/OpenMobileCategories.vue').default);
Vue.component('last-seen-carousel', require('./components/Carousel.vue').default);
// Vue.component('product-preview', require('./components/ProductPreview.vue').default);
Vue.component('add-to-cart-btn', require('./components/AddToCartBtn.vue').default);
Vue.component('add-to-cart-modal', require('./components/AddToCartModal.vue').default);
Vue.component('notifications-wrapper', require('./components/Notifications.vue').default);
Vue.component('notification-item', require('./components/NotificationItem.vue').default);
Vue.component('filter-btn', require('./components/FilterBtn.vue').default);
Vue.component('sort-btn', require('./components/SortBtn.vue').default);
Vue.component('search-keyword', require('./components/SearchKeyword.vue').default);
Vue.component('display-products', require('./components/DisplayProducts.vue').default);
Vue.component('send-mail-friend', require('./components/SendMailFriend.vue').default);
Vue.component('stepper-input', require('./components/StepperInput.vue').default);
Vue.component('create-address-modal', require('./components/CreateAddressModal.vue').default);
Vue.component('delivery-method', require('./components/DeliveryMethodRadioBtn.vue').default);
Vue.component('payment-method', require('./components/PaymentMethodRadioBtn.vue').default);
Vue.component('price-calculator', require('./components/PriceCalculator.vue').default);
Vue.component('address-radio', require('./components/AddressRadioBtn.vue').default);


Vue.component('select-listen-change', require('./components/SelectListenChange.vue').default);



//admin
Vue.component('create-attribute', require('./components/CreateAttribute.vue').default);
Vue.component('create-attribute-set', require('./components/CreateAttributeSet.vue').default);
Vue.component('switch-attributes', require('./components/SwitchAttributes.vue').default);
*/
