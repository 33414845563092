<template>
    <div class="mobile-categories" :class="openMenu == true ? 'mobile-menu-open' : ''" style="z-index:99">
        <div class="h4">Oferta</div>
        <ul class="list-unstyled pt-1 m-0">
            <li v-for="(category) in categories" :key="category.id">
                <a :href="'/kategorie/' + category.id+'-'+category.slug" class="mobile-category-link" v-on:click="(category.subcategories != null) ? openSubmenu($event, category.id) : openLink($event)">{{category.name}}</a>
                <div class="mobile-submenu" :class="submenuOpenId == category.id ? 'mobile-menu-open' : ''">
                    <div>
                        <a :href="'/kategorie/'+category.id+'-'+category.slug" v-on:click.prevent="closeSubmenu()" class="d-block py-2 text-center text-dark text-decoration-none border bg-white">Powrót</a>
                    </div>
                    <div class="pt-3">
                        <a :href="'/kategorie/'+category.id+'-'+category.slug" class="d-block py-2 text-center text-dark text-decoration-none border bg-white">Pokaż wszystko w Firany</a>
                    </div>
                    <ul class="list-unstyled pt-1 m-0" v-if="category.subcategories != null && category.subcategories.length > 0">
                        <li v-for="subcategory in category.subcategories" :key="subcategory.id">
                            <a :href="'/kategorie/'+subcategory.id+'-'+subcategory.slug" class="mobile-submenu-link">{{subcategory.name}}</a>
                            <ul v-if="(subcategory.subcategories != null ? (subcategory.subcategories.length > 0) : '')" class="list-unstyled pl-4">
                                <li v-for="subsubcategory in subcategory.subcategories" :key="subsubcategory.id">
                                    <a :href="'/kategorie/'+subsubcategory.id+'-'+subsubcategory.slug" class="mobile-submenu-link">{{subsubcategory.name}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import { bus } from '../app';
    export default {

        props: ['c'],

        mounted() {
            bus.$on('open-mobile-menu', (f) => f ? this.open() : this.close());
        },

        data: function() {
            return {
                openMenu: false,
                submenuOpenId: false,
                categories: JSON.parse(this.c),
            };
        },

        methods: {
            open() {
                this.openMenu = true;
            },
            
            close() {
                this.openMenu = false;
                this.submenuOpenId = false;
            },

            openSubmenu(e, id) {
                e.preventDefault();
                this.submenuOpenId = id;
            },

            closeSubmenu() {
                this.submenuOpenId = false;
            },

            openLink(e) {
                // e.stopPropagation();
                // console.log(e);
            }
        },
    }
</script>
