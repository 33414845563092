<template>
    <div class="notifications-container">
        <ul class="fx-list p-0 m-0 notifications-list" ref="notifications-list">
            <li v-for="(text, index) in notifications" :key="index">
                <notification-item :msg='text'></notification-item>
            </li>
        </ul>
    </div>
</template>

<script>
    import { bus } from '../app';
    export default {

        props: [],

        mounted() {
            //create notification from event bus
            bus.$on('create-notification', msg => {
                this.createNotification(msg);
            });


            //Create notifications from span with create-notification class
            let _this = this;
            $('.create-notification').each(function() {
                _this.createNotification($( this ).text());
            });
        },

        data: function() {
            return {
                notifications: []
            }
        },

        methods: {
            createNotification(msg) {
                this.notifications.push(msg);
            }
        },
    }
</script>
