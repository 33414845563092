<template>
    <a :href="href" class="d-block p-2" v-on:click.prevent='click'>
        <img src="/images/icons/search-categories.svg" class="d-block mobile-nav-icon mx-auto" alt="Kategorie">
        <span>Oferta</span>
    </a>
</template>

<script>
    import { bus } from '../app';
    export default {

        props: ['href'],

        data: function() {
            return {
                open: false,
            };
        },

        methods: {
            click() {
                this.open = !this.open;
                bus.$emit('open-mobile-menu', this.open);
            }
        },
    }
</script>
